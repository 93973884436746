<template>
  <div>
    <Toolbar
      :handle-reset="resetForm"
      :handle-submit="onUpdateTenantCompanySettingForm"
    />
    <TenantCompanySettingForm
      :errors="violations"
      :values="item"
      ref="updateForm"
      v-if="item.address"
    />
    <Loading :visible="isLoading"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";
import TenantCompanySettingForm from "../../components/tenantCompanySetting/Form.vue";
import Loading from "@/components/Loading";
import Toolbar from "@/components/Toolbar";
import UpdateMixin from "../../mixins/UpdateMixin";
import DocumentMixin from "../../mixins/DocumentMixin";

const servicePrefix = "TenantCompanySetting";

export default {
  name: "TenantCompanySettingUpdate",
  servicePrefix,
  mixins: [UpdateMixin, DocumentMixin],
  data() {
    return {
      item: {},
      updateMessage: this.$t("tenantCompanySettingUpdateSuccess"),
      updateRoute: null,
      uploads: [],
    };
  },
  components: {
    Loading,
    Toolbar,
    TenantCompanySettingForm,
  },
  watch: {
    uploadedDocument() {
      if (!this.uploadedDocument) {
        return;
      }
      switch (true) {
        case this.$refs.updateForm.logoUpload !== null &&
        this.$refs.updateForm.logoUpload.name ===
        this.uploadedDocument["name"]:
          this.item.logo = this.uploadedDocument["@id"];
          this.uploads.splice(this.uploads.indexOf("logo"), 1);
          break;
        case this.$refs.updateForm.letterPaperFirstUpload !== null &&
        this.$refs.updateForm.letterPaperFirstUpload.name ===
        this.uploadedDocument["name"]:
          this.item.letterPaperFirst = this.uploadedDocument["@id"];
          this.uploads.splice(this.uploads.indexOf("letterPaperFirst"), 1);
          break;
        case this.$refs.updateForm.letterPaperFurtherUpload !== null &&
        this.$refs.updateForm.letterPaperFurtherUpload.name ===
        this.uploadedDocument["name"]:
          this.item.letterPaperFurther = this.uploadedDocument["@id"];
          this.uploads.splice(this.uploads.indexOf("letterPaperFurther"), 1);
          break;
      }

      !this.uploads.length && this.onSendForm();
    },
    item() {
      if (this.afterUpdate) {
        this.afterUpdate = () => {
          this.$router.go()
        }
      }
    }
  },
  computed: {
    ...mapFields("tenantCompanySetting", {
      isLoading: "isLoading",
      error: "error",
      updated: "updated",
      violations: "violations",
    }),
    ...mapGetters("tenantCompanySetting", ["find"]),
  },
  methods: {
    ...mapActions("tenantCompanySetting", {
      retrieve: "load",
      update: "update",
    }),
    onUpdateTenantCompanySettingForm() {
      if (
        this.isValidForm() &&
        (this.$refs.updateForm.logoUpload ||
          this.$refs.updateForm.letterPaperFirstUpload ||
          this.$refs.updateForm.letterPaperFurtherUpload)
      ) {
        if (this.$refs.updateForm.logoUpload) {
          this.uploads["logo"] = 1;
          this.uploadDocument(this.$refs.updateForm.logoUpload);
        }

        if (this.$refs.updateForm.letterPaperFirstUpload) {
          this.uploads["letterPaperFirst"] = 1;
          this.uploadDocument(this.$refs.updateForm.letterPaperFirstUpload, true);
        }

        if (this.$refs.updateForm.letterPaperFurtherUpload) {
          this.uploads["letterPaperFurtherUpload"] = 1;
          this.uploadDocument(this.$refs.updateForm.letterPaperFurtherUpload, true);
        }
        return;
      }
      this.onSendForm();
    },
  },
};
</script>
